import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const VirylIcon: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (window.location.pathname !== '/') {
            navigate('/');
        }
    };

    return (
        <ScrollLink to="hero" smooth={true} duration={500} offset={-100} onClick={handleClick}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}>
                <img src="/viryl.png" alt="Viryl Logo" style={{ width: '32px', height: '32px' }} />
                <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', color: '#1AB69D', mr: 2 }}>
                    Viryl
                </Typography>
            </Box>
        </ScrollLink>
    );
};

export default VirylIcon;