import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VirylIcon from './VirylIcon';
import { useUser } from '../contexts/UserContext';
import { Typography, Avatar } from '@mui/material';
import GoogleLogin from './GoogleLogin';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
    backdropFilter: 'blur(24px)',
    border: '1px solid',
    borderColor: theme.palette.divider,
    backgroundColor: alpha(theme.palette.background.default, 0.4),
    boxShadow: theme.shadows[1],
    padding: '8px 12px',
}));

export default function AppAppToo() {
    const [open, setOpen] = React.useState(false);
    const { user, logout } = useUser(); // Destructure logout from the context
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const handleUserClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const renderUserInfo = () => (
        <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 2, cursor: 'pointer' }}
            onClick={handleUserClick}
        >
            <Typography sx={{ color: 'black' }} variant="body1">Hello, {user?.name}</Typography>
            <Avatar src={user?.picture} alt={user?.name} />
        </Box>
    );
    const handleLogout = () => {
        logout(); // Use the logout function from UserContext
    }; const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar
            position="static"
            sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', pt: 4 }}
        >
            <Container maxWidth="xl">
                <StyledToolbar variant="dense" disableGutters>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
                        <VirylIcon />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Button variant="text" color="info" size="small" onClick={() => navigate('/viral-action')}>
                                Generate Clips
                            </Button>
                            <Button variant="text" color="info" size="small" onClick={() => navigate('/viral-video')}>
                                My Clips
                            </Button>
                            <Button variant="text" color="info" size="small" onClick={() => navigate('/subscription-management')}>
                                Manage Subscription
                            </Button>

                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        {user ? renderUserInfo() : <GoogleLogin />}
                    </Box>
                    <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
                        <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
                            <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <IconButton onClick={toggleDrawer(false)}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Box>
                                <Divider sx={{ my: 3 }} />
                                <MenuItem onClick={() => navigate('/viral-action')}>Generate Clips</MenuItem>
                                <MenuItem onClick={() => navigate('/viral-video')}>My Clips</MenuItem>
                                <MenuItem onClick={() => navigate('/subscription-management')}>Manage Subscription</MenuItem>
                                <MenuItem>
                                    {user ? renderUserInfo() : <GoogleLogin />}
                                </MenuItem>
                            </Box>
                        </Drawer>
                    </Box>
                </StyledToolbar>
            </Container>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
            >
                <MenuItem onClick={() => handleLogout()}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

        </AppBar >
    );
}
