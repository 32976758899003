import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Container, Box, Typography, IconButton, Button } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios'; // Update axios import

import Hero from './components/Hero';
import AppBarToo from './components/AppBarToo';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';

export interface VideoData {
    id: string;
    videoUrl: string;
    title: string;
    description: string;
}

export function VideoBox({ videoData }: { videoData: VideoData }) {
    const [videoKey, setVideoKey] = React.useState(0);
    const [addingSubtitles, setAddingSubtitles] = React.useState<'idle' | 'loading' | 'success'>('idle');

    const handleDownload = async () => {
        const video = videoData.videoUrl
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/download_video`, {
                params: { video: vid_path },
                responseType: 'blob', // Important for handling binary data
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${videoData.title}.mp4`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading video:', error);
        }
    };

    // Update the handleAddSubs function
    const handleAddSubs = async () => {
        setAddingSubtitles('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/add_subs`, {
                params: { video: vid_path },
            });

            setAddingSubtitles('success');

            // Update the video URL with the new path received from the server
            videoData.videoUrl = response.data.updated_video_url;

            setVideoKey(prevKey => prevKey + 1);
        } catch (error) {
            console.error('Error adding subtitles:', error);
            setAddingSubtitles('idle');
        }
    };

    return (
        <Grid item xs={12} sm={6} lg={3}>
            <Box sx={{
                borderRadius: 4,
                overflow: 'hidden',
                boxShadow: 3,
                position: 'relative',
                paddingTop: '175%',
                width: '100%',
                height: 0,
                bgcolor: 'grey.300',
            }}>
                <video
                    key={videoKey}
                    controls
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Add this to maintain aspect ratio while filling the container
                    }}
                >
                    <source src={videoData.videoUrl} type="video/mp4" />
                    <source src={videoData.videoUrl} type="video/webm" />
                </video>
            </Box>
            <Box sx={{ mt: 1 }}>
                <Box>
                    <Typography variant="h6">{videoData.title}</Typography>
                    <Typography variant="body2">{videoData.description}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 1,
                    flexWrap: 'wrap',
                }}>
                    <Tooltip
                        title={addingSubtitles === 'loading' ? "This may take a while..." : addingSubtitles === 'success' ? "" : "Add Subtitles"}
                        arrow
                    >
                        <span>
                            <IconButton
                                onClick={handleAddSubs}
                                color="primary"
                                sx={{ mb: 1, mr: 1, width: 40, height: 40 }}
                                disabled={addingSubtitles !== 'idle'}
                            >
                                {addingSubtitles === 'idle' && <AddIcon />}
                                {addingSubtitles === 'loading' && <CircularProgress size={24} />}
                                {addingSubtitles === 'success' && <CheckIcon sx={{ color: 'green' }} />}
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Download Video" arrow>
                        <IconButton onClick={handleDownload} aria-label="download video" sx={{ mb: 1 }}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Grid>
    );
}

function LoadingVideoBox({ status = '' }: { status?: string }) {
    const isActive = status !== '';

    return (
        <Grid item xs={3}>
            <Box sx={{
                borderRadius: 4,
                overflow: 'hidden',
                boxShadow: 3,
                position: 'relative',
                paddingTop: '175%',
                width: '100%',
                bgcolor: 'grey.300',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {isActive && <CircularProgress sx={{ position: 'absolute', top: '40%', transform: 'translate(-50%, -50%)' }} />}
                {isActive && (
                    <Typography
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            top: '60%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            width: '80%',
                        }}
                    >
                        {status}
                    </Typography>
                )}
            </Box>
            <Skeleton variant="text" sx={{ fontSize: '1.25rem', mt: 1 }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Grid>
    );
}

function ViralActionPage() {
    const location = useLocation();
    const { videoDataArray = [], statusUpdates = [], error, errorMessage } = location.state || {};

    const handleDownloadAll = async () => {
        if (videoDataArray.length === 0) return;

        // Extract directory path from the first video's URL
        const firstVideoUrl = videoDataArray[0].videoUrl;
        const url = new URL(firstVideoUrl);
        let directory = decodeURIComponent(url.pathname.substring(0, url.pathname.lastIndexOf('/')));
        directory = directory.replace(/^\/video\//, ''); // Remove leading "/video/"

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/download_videos`, {
                params: { directory },
                responseType: 'blob', // Important for handling binary data
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'videos.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading videos:', error);
        }
    };

    const lastStatus = statusUpdates[statusUpdates.length - 1] || '';

    const isProcessing = lastStatus.toLowerCase() !== 'done';
    const hasError = statusUpdates.some((status: string) => status.toLowerCase().includes('error'));

    if (error || (hasError && videoDataArray.length === 0) || (!isProcessing && videoDataArray.length === 0)) {
        return (
            <>
                <AppBarToo />
                <Hero error={true} message={errorMessage || "There was an error handling your request. Please try again later"} landing={false} />
            </>
        );
    }

    // Check if there are no status updates
    if (statusUpdates.length === 0) {
        return (
            <>
                <AppBarToo />
                <Hero landing={false} />
            </>
        );
    }
    return (
        <Container maxWidth="lg">
            <AppBarToo />
            <Box sx={{ pt: 8, pb: 6, p: 4, margin: 'auto', mt: 0, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {videoDataArray[0] ? videoDataArray[0].id : 'Generating Clips...'}
                </Typography>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {videoDataArray.map((videoData: VideoData, index: number) => (
                        <VideoBox key={videoData.id} videoData={videoData} />
                    ))}
                    {isProcessing && !hasError && (
                        <LoadingVideoBox
                            status={lastStatus}
                        />
                    )}
                </Grid>
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleDownloadAll}
                        startIcon={<DownloadIcon />}
                        disabled={isProcessing}
                    >
                        Download All
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default ViralActionPage;