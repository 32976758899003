import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import GoogleLogin from './GoogleLogin';
import { useLogin } from '../contexts/LoginContext';

const LoginModal: React.FC = () => {
    const { isLoginModalOpen, closeLoginModal } = useLogin();

    return (
        <Dialog open={isLoginModalOpen} onClose={closeLoginModal}>
            <DialogTitle>Sign in to continue</DialogTitle>
            <DialogContent>
                <GoogleLogin onSuccess={closeLoginModal} />
            </DialogContent>
        </Dialog>
    );
};

export default LoginModal;