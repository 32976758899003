import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function AboutUs() {
    return (
        <Container maxWidth="md">
            <Box sx={{ pt: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    About Viryl
                </Typography>
                <Typography variant="body1" paragraph>
                    As a solo developer and graduate student from Germany, I created Viryl to help content creators maximize their reach through automated video clipping.
                </Typography>
                <Typography variant="body1" paragraph>
                    My journey in software development led me to recognize the potential in streamlining the content creation process. Viryl is born from the desire to empower creatives by simplifying the tedious task of creating engaging clips from longer content.
                </Typography>
                <Typography variant="body1" paragraph>
                    With Viryl, I'm not just building a tool; I'm creating an opportunity for content creators to focus on what they do best - creating amazing content - while our platform handles the intricacies of clip generation and distribution.
                </Typography>
                <Typography variant="body1" paragraph>
                    While Viryl is still in its early stages and may have some rough edges, I'm incredibly eager to improve the product based on user feedback. I'm deeply grateful for anyone who signs up to try Viryl or joins our Discord community to share their thoughts and experiences.
                </Typography>
                <Typography variant="body1">
                    Your support and feedback are invaluable in shaping Viryl into a tool that truly serves content creators' needs. Together, we can refine and enhance this platform to make content creation even more accessible and efficient.
                </Typography>
            </Box>
        </Container>
    );
}