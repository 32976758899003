import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import AppBarToo from './components/AppBarToo';
import Hero from './components/Hero';
// eslint-disable-next-line
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getMPTheme from './theme/getMPTheme';
import { useUser } from './contexts/UserContext';

export default function MarketingPage() {
  const MPTheme = createTheme(getMPTheme("light"));
  const { user } = useUser();

  return (
    <ThemeProvider theme={MPTheme}>
      <CssBaseline />
      {user ? <AppBarToo /> : <AppAppBar />}
      <div id="hero">
        <Hero />
      </div>
      <div>
        <div id="features">
          <Features />
        </div>
        <Divider />
        {/* <div id="highlights">
          <Highlights />
        </div> */}
        <Divider />
        <div id="pricing">
          <Pricing />
        </div>
        <Divider />
        <div id="faq">
          <FAQ />
        </div>
        <Divider />
        <Footer />
      </div>
    </ThemeProvider>
  );
}
